import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Image } from '@/src/components/atoms';
import { SectionHeader, TypedText } from '@/src/components/molecules';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  fontWeight600: {
    fontWeight: 600,
  },
  leftSideContent: {
    '& .section-header__cta-container': {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        '& .section-header__cta-item-wrapper': {
          width: '100%',
          '&:last-child': {
            marginLeft: 0,
            marginTop: theme.spacing(1),
          },
          '& .MuiButtonBase-root': {
            width: '100%',
          },
        },
      },
    },
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  image: {
    maxWidth: 400,
    height: 'auto',
    width: '100%',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const Hero = props => {
  const { cta, className, ...rest } = props;

  const classes = useStyles();

  const title = (
    <Typography variant="h2" component="span" className={classes.fontWeight600}>
      Uma solução completa para você vender <br />
      <TypedText
        component="span"
        variant="h2"
        color="primary"
        className={classes.fontWeight600}
        typedProps={{
          strings: ['online!'],
          typeSpeed: 100,
          backSpeed: 50,
          loop: true,
        }}
      />
    </Typography>
  );

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={7}>
          <SectionHeader
            title={title}
            subtitle="Somos a única plataforma totalmente customizada para o setor educacional."
            align="left"
            titleProps={{ variant: 'h2', color: 'textPrimary' }}
            subtitleProps={{ fontStyle: 'italic' }}
            ctaGroup={[cta]}
            disableGutter
            className={classes.leftSideContent}>
            <Typography variant="subtitle1" color="textSecondary">
              Crie um e-commerce com a sua marca e tenha à sua disposição funcionalidades
              customizadas para ajudar você a alavancar vendas, gerenciar estoque, controlar os
              pagamentos e comissões tudo em um único local.
            </Typography>
          </SectionHeader>
        </Grid>
        <Grid item container xs={12} sm={5} alignItems="center">
          <div className={classes.image}>
            <Image src="/images/illustrations/home/hero.svg" alt="Eskolare" lazy={false} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Hero.propTypes = {
  cta: PropTypes.any,
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Call to action to use with Hero
   */
  cta: PropTypes.any,
};

export default Hero;
