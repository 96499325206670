import Image from 'next/image';
import Link from 'next/link';

import {
  useMediaQuery,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import useSWR from 'swr';

import { SectionHeader } from '@/src/components/molecules';
import { nextAPIfetcher } from '@/src/lib/utils';

SwiperCore.use([Pagination]);

const useStyles = makeStyles(theme => ({
  articleDate: {
    margin: theme.spacing(2, 0),
  },
  card: {
    boxShadow: 'none',
    border: 0,
    maxWidth: 300,
  },
  cardMedia: {
    position: 'relative',
    width: 300,
    height: 185,
  },
  swiperSlide: {
    width: 'auto',
  },
}));

export default function ArticlesCard({ className, ...rest }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true });
  const { data } = useSWR(`/success-cases/articles/latest?pageSize=12`, nextAPIfetcher);
  const { results = [] } = data || {};

  return (
    <div className={className} {...rest}>
      <SectionHeader
        label="Casos de sucesso"
        title="Conheça as histórias dos nossos Clientes:"
        align="left"
      />
      <Swiper
        slidesPerView="auto"
        spaceBetween={isMd ? 30 : 12}
        allowSlideNext
        allowSlidePrev
        allowTouchMove
        pagination={{
          clickable: true,
        }}
        className="mySwiper">
        {results.map(article => (
          <SwiperSlide key={article.uid} className={classes.swiperSlide}>
            <Card className={classes.card}>
              <Link href={`/success-cases/article/${article.uid}`} passHref>
                <CardActionArea component="a">
                  <CardMedia className={classes.cardMedia}>
                    {article.data?.cover?.url && (
                      <Image
                        src={article.data?.cover?.url}
                        alt={article.data?.cover?.alt || article.data?.title}
                        layout="fill"
                        objectFit="cover"
                        quality={30}
                      />
                    )}
                  </CardMedia>
                  <CardContent>
                    <Typography
                      variant="body1"
                      color="primary"
                      component="p"
                      className={classes.articleDate}>
                      <i>Por {article.data?.author?.data?.name}</i>&nbsp;|&nbsp;
                      <i>
                        <FormattedDate value={article.data?.date} />
                      </i>
                    </Typography>
                    <Typography variant="h6" color="textPrimary" component="p">
                      {article.data?.title}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

ArticlesCard.propTypes = {
  className: PropTypes.string,
};
