import { useRef } from 'react';

import { Button, makeStyles } from '@material-ui/core';

import PropTypes from 'prop-types';

import { Section, SectionAlternate } from '@/src/components/organisms';
import { ArticlesCard } from '@/src/containers/cards';
import { scroller } from '@/src/lib/utils';

import { Features, Hero, Network, Referral, Segments, Sellers, Setup, Stats } from './components';

const useStyles = makeStyles(theme => ({
  sectionHero: {
    backgroundColor: theme.palette.background.level2,
    borderRadius: '0 0 60% 0',
  },
  sectionAlternate: {
    background: 'transparent',
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 400px, ${theme.palette.primary.light} 0%)`,
  },
}));

function IndexView() {
  const classes = useStyles();

  const formRef = useRef();

  return (
    <div>
      <div className={classes.sectionHero}>
        <Section>
          <Hero
            cta={
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={() => setTimeout(() => scroller(formRef?.current?.offsetTop - 100), 500)}
                aria-label="Quero falar com um especialista"
                key="openJobsCta">
                Quero falar com um especialista
              </Button>
            }
          />
        </Section>
      </div>
      <Section>
        <Setup />
      </Section>
      <SectionAlternate>
        <Segments />
      </SectionAlternate>
      <Section>
        <Network />
      </Section>
      <SectionAlternate>
        <Sellers />
      </SectionAlternate>
      <Section>
        <Features />
      </Section>
      <div className={classes.sectionAlternate}>
        <Section>
          <Referral formRef={formRef} />
        </Section>
      </div>
      <SectionAlternate>
        <ArticlesCard />
      </SectionAlternate>
      <Stats />
    </div>
  );
}

IndexView.propTypes = {
  doc: PropTypes.object,
};

export default IndexView;
