import dynamic from 'next/dynamic';

export { default as Hero } from './Hero';
export { default as Personas } from './Personas';

export const Faq = dynamic(() => import('./Faq'));
export const Features = dynamic(() => import('./Features'));
export const Find = dynamic(() => import('./Find'));
export const FooterNewsletter = dynamic(() => import('./FooterNewsletter'));
export const Network = dynamic(() => import('./Network'));
export const News = dynamic(() => import('./News'));
export const Referral = dynamic(() => import('./Referral'));
export const Reviews = dynamic(() => import('./Reviews'));
export const Segments = dynamic(() => import('./Segments'));
export const Sellers = dynamic(() => import('./Sellers'));
export const Setup = dynamic(() => import('./Setup'));
export const Stats = dynamic(() => import('./Stats'));
export const TrackOrder = dynamic(() => import('./TrackOrder'));
