const nextAPIfetcher = async url => {
  const res = await fetch(`/api${url}`);
  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.');
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }
  return res.json();
};

export default nextAPIfetcher;
