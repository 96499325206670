import dynamic from 'next/dynamic';

export { default as Section } from './Section';

export const Accordion = dynamic(() => import('./Accordion'));
export const CardBase = dynamic(() => import('./CardBase'));
export const CardCategory = dynamic(() => import('./CardCategory'));
export const CardCategoryLink = dynamic(() => import('./CardCategoryLink'));
export const CardJob = dynamic(() => import('./CardJob'));
export const CardJobCompany = dynamic(() => import('./CardJobCompany'));
export const CardJobMinimal = dynamic(() => import('./CardJobMinimal'));
export const CardJobTag = dynamic(() => import('./CardJobTag'));
export const CardPricingStandard = dynamic(() => import('./CardPricingStandard'));
export const CardProduct = dynamic(() => import('./CardProduct'));
export const CardPromo = dynamic(() => import('./CardPromo'));
export const CardReview = dynamic(() => import('./CardReview'));
export const ContactForm = dynamic(() => import('./ContactForm'));
export const DescriptionListIcon = dynamic(() => import('./DescriptionListIcon'));
export const EmptyState = dynamic(() => import('./EmptyState'));
export const HeroBackground = dynamic(() => import('./HeroBackground'));
export const HeroShaped = dynamic(() => import('./HeroShaped'));
export const HeroSideImage = dynamic(() => import('./HeroSideImage'));
export const HeroSimpleBackground = dynamic(() => import('./HeroSimpleBackground'));
export const Map = dynamic(() => import('./Map'), { ssr: false });
export const Parallax = dynamic(() => import('./Parallax'));
export const SectionAlternate = dynamic(() => import('./SectionAlternate'));
export const DialogMinimal = dynamic(() => import('./DialogMinimal'));
